


<template>
    <!-- 耕雲導師年谱初稿 -->
    <div class="firstDraft " v-html="content.news_content"></div>
</template>

<script>
import { getCateList, getChronicle } from "../../api/apis";
export default {
    name: "",

    data() {
        return {
            content: {}
        };
    },
    created() {
        getCateList().then(res => {
            let id = res[0][4].id;
            getChronicle(id).then(res => {
                res.news_content = res.news_content.replace(/<table /g,'<table border="1px" ');
                this.content = res;                                                               
            });
        });
    }
};
</script>

<style lang="stylus" scoped>
.firstDraft {
    width: 776px;
    margin: 20px 5px;
    line-height: 28px;
    text-align left;
}                                                                                                                                           
</style>
<style>
	.firstDraft>table {
		width: 776px !important;
	}
</style>